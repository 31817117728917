@import url('fonts.css');
@import url('media.css');

*{
    box-sizing: border-box;
}

body{
    font-family: green-groove;
    position: relative;
    background-image: url('../images/regbg.png');
    background-size: cover;
    background-position: 50% 20%;
    color: #fff;
    overflow-x: hidden;
    margin: 0 auto;
}

a{
    color: #fff;
    text-decoration: none;
}

:root{
    --font-yellow: #E8B82F;
}

.text-shadow{
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.593);
}

.page-header{
    color: var(--font-yellow);
}

.date{
    font-size: 24px;
    color: var(--font-yellow);
    font-weight: bold;
    margin-top: 5px;
}

@media screen and (max-width: 800px){
    .page-header{
        font-size: 20px;
        margin: 30px 0;
    }
}

