.wrapper{
    position: fixed;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.323);
    padding: 10px;
    border-radius: 0 0 15px 15px;
    font-size: 20px;
    cursor: pointer;
    transition: .5s all;
    z-index: 99;
    top: 0;
    right: 20%;
}

.wrapper:hover{
    background-color: rgba(0, 0, 0, 0.536);
}

.wrapper img{
    width: 30px;
    padding-left: 10px;
}

@media screen and (max-width: 500px) {
    .wrapper{
        right: 5%;
    }
}