.wrapper{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    height: 100vh;
}

.logo{
    margin-top: 10px;
}

.logo img{
    width: 200px;
}

@media screen and (max-width: 800px){
    .date{
        font-size: 18px;
        margin-bottom: 30px;
    }

    .logo img{
        width: 150px;
    }
}

@media screen and (max-width: 500px){
    .wrapper{
        height: initial !important;
    }
}