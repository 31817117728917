.wrapper{
    width: 30%; 
    margin-bottom: 30px;
}

.wrapper img{
    width: 100%;
    height: 100%;
    max-height: 280px;
    object-fit: cover;
}


@media screen and (max-width: 800px){
    .wrapper{
        width: 60%;
        height: auto;
    }
}

@media screen and (max-width: 500px){
    .wrapper{
        width: 80%;
    }
}