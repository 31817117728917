.goToMain{
    position: fixed;
    left: 20%;
    top: 0;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.323);
    padding: 10px;
    border-radius: 0 0 15px 15px;
}

@media screen and (max-width: 500px){
    .goToMain{
        left: 5%;
    }
}