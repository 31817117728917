
.logo{
    margin-top: 20px;
}

.logo img{
    width: 140px;
}

.idleSection{
    display: flex;
    flex-direction: column;
    align-items: center;

}

@media screen and (max-width: 800px){
    .logo img{
        width: 100px;
    }
    
}