.wrapper{
    display: grid;
    grid-template-columns: 30% 30%;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 200px;
    margin-top: 100px;
}

.wrapper img{
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.wrapper p{
    font-size: 22px;
}

.imageContain{
    object-fit: contain !important;
}

@media screen and (max-width: 1200px) {
    .wrapper{
        grid-template-columns: 40% 40%;
    }

    .wrapper img{
        height: 300px;
    }
}

@media screen and (max-width: 800px) {
    .wrapper{
        grid-template-columns: 45% 45%;
    }
}

@media screen and (max-width: 500px){
    .wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0;
        padding: 0 10px;
    }
}