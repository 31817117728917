.Columns_colLeft__2-C8u, .Columns_colRight__28F1R{
    position: absolute;
    top: 0px;
}

.Columns_colLeft__2-C8u img, .Columns_colRight__28F1R img{
    height: 98vh;
}

.Columns_colLeft__2-C8u{
    left: 0;
}

.Columns_colRight__28F1R{
    right: 0;
}

@media screen and (max-width: 930px){
    .Columns_colLeft__2-C8u, .Columns_colRight__28F1R{
        display: none;
    }
}
.Footer_wrapper__45Qju{
    width: 100%;
    background-color: rgba(0,0,0, .2);
    text-align: center;
    padding-bottom: 20px;
}

.Footer_wrapper__45Qju h2{
    padding-top: 20px;
}

.Footer_socials__2oGKS{
    display: flex;
    justify-content: center;
    grid-gap: 0 20px;
    gap: 0 20px;
}

.Footer_social__2yIBM img{
    width: 30px;
}

.Footer_maps__21Uvu{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.Footer_footerItem__1J9vQ{
    font-size: 22px;
    margin: 15px 20px;
}

.Footer_footerItem__1J9vQ img{
    width: 15px;
    margin-right: 5px;
}

.Main_wrapper___-TEP{
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    height: 100vh;
}

.Main_logo__1IWP3{
    margin-top: 10px;
}

.Main_logo__1IWP3 img{
    width: 200px;
}

@media screen and (max-width: 800px){
    .Main_date__2Mhm1{
        font-size: 18px;
        margin-bottom: 30px;
    }

    .Main_logo__1IWP3 img{
        width: 150px;
    }
}

@media screen and (max-width: 500px){
    .Main_wrapper___-TEP{
        height: initial !important;
    }
}
.Navbar_links__3Nm13{
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
}

.Navbar_links__3Nm13 a{
    color: var(--font-yellow);
    text-decoration: none;
}

.Navbar_linkTitle__3Exoy{
    font-size: 24px;
    font-weight: bold;
    color: var(--font-yellow);
    text-align: center;
}

.Navbar_link__1sUBc{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 25px;
    padding: 5px;
}


.Navbar_linkImage__2xqWI img{
    width: 150px;
}

.Navbar_linkImage__2xqWI{
    transition: .5s all;
}

.Navbar_linkArrow__35RgK img{
    width: 90px;
}

.Navbar_link__1sUBc:hover .Navbar_linkImage__2xqWI{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.Navbar_link__1sUBc:hover{
    background-color: rgba(0, 0, 0, 0.122);
}

@media screen and (max-width: 1600px){
    .Navbar_links__3Nm13{
        margin-top: 40px;
    }
}

@media screen and (max-width: 800px){
    .Navbar_linkImage__2xqWI img{
        width: 100px;
    }

    .Navbar_linkArrow__35RgK img{
        width: 60px;
    }

    .Navbar_linkTitle__3Exoy{
        font-size: 18px;
    }
}

@media screen and (max-width: 480px){
    .Navbar_links__3Nm13{
        display: flex;
        flex-direction: column;
    }

    .Navbar_linkArrow__35RgK{
        display: none;
    }

    .Navbar_linkImage__2xqWI img{
        width: 150px;
    }

    .Navbar_linkTitle__3Exoy{
        font-size: 25px;
    }
}
.SectionText_sectionText__6Wo5e{
    font-size: calc(1vw + 1vh + 4px);
    font-family: green-groove;
    letter-spacing: 1px;
    text-align: center;
    margin-top: 40px;
    max-width: 50%;
    position: relative;
    --sectionTextImgWidth: 280px;
}

.SectionText_sectionText__6Wo5e img{
    position: absolute;
    left: calc(50% - var(--sectionTextImgWidth) / 2);
    top: -45px;
    width: var(--sectionTextImgWidth);
}

.SectionText_sectionText__6Wo5e span{
    font-weight: bold;
}

@media screen and (max-width: 1600px){
    .SectionText_sectionText__6Wo5e{
        font-size: calc(1vw + 1vh + 1px);
        margin-top: 40px;
    }
}

@media screen and (max-width: 1000px){
    .SectionText_sectionText__6Wo5e{
        --sectionTextImgWidth: 180px; 
    }
}

@media screen and (max-width: 800px){
    .SectionText_sectionText__6Wo5e{
        font-size: 20px;
        max-width: 100%;
    }
}


.Pages_logo__a8qpu{
    margin-top: 20px;
}

.Pages_logo__a8qpu img{
    width: 140px;
}

.Pages_idleSection__1Ufa0{
    display: flex;
    flex-direction: column;
    align-items: center;

}

@media screen and (max-width: 800px){
    .Pages_logo__a8qpu img{
        width: 100px;
    }
    
}
.SlickGallery_wrapper__1BaR0{
    width: 30%; 
    margin-bottom: 30px;
}

.SlickGallery_wrapper__1BaR0 img{
    width: 100%;
    height: 100%;
    max-height: 280px;
    object-fit: cover;
}


@media screen and (max-width: 800px){
    .SlickGallery_wrapper__1BaR0{
        width: 60%;
        height: auto;
    }
}

@media screen and (max-width: 500px){
    .SlickGallery_wrapper__1BaR0{
        width: 80%;
    }
}
.ScrollArrow_linkArrow__2gjkD img{
    width: 90px;
}
.PhotoAndText_wrapper__3d147{
    display: grid;
    grid-template-columns: 30% 30%;
    justify-content: center;
    align-items: center;
    grid-gap: 50px;
    gap: 50px;
    margin-bottom: 200px;
    margin-top: 100px;
}

.PhotoAndText_wrapper__3d147 img{
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.PhotoAndText_wrapper__3d147 p{
    font-size: 22px;
}

.PhotoAndText_imageContain__2AbJs{
    object-fit: contain !important;
}

@media screen and (max-width: 1200px) {
    .PhotoAndText_wrapper__3d147{
        grid-template-columns: 40% 40%;
    }

    .PhotoAndText_wrapper__3d147 img{
        height: 300px;
    }
}

@media screen and (max-width: 800px) {
    .PhotoAndText_wrapper__3d147{
        grid-template-columns: 45% 45%;
    }
}

@media screen and (max-width: 500px){
    .PhotoAndText_wrapper__3d147{
        display: flex;
        flex-direction: column;
        align-items: center;
        grid-gap: 0;
        gap: 0;
        padding: 0 10px;
    }
}
.GoToMain_goToMain__1GSmo{
    position: fixed;
    left: 20%;
    top: 0;
    color: #fff;
    text-decoration: none;
    font-size: 20px;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.323);
    padding: 10px;
    border-radius: 0 0 15px 15px;
}

@media screen and (max-width: 500px){
    .GoToMain_goToMain__1GSmo{
        left: 5%;
    }
}
.MusicPopup_wrapper__pkLeC{
    position: fixed;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.323);
    padding: 10px;
    border-radius: 0 0 15px 15px;
    font-size: 20px;
    cursor: pointer;
    transition: .5s all;
    z-index: 99;
    top: 0;
    right: 20%;
}

.MusicPopup_wrapper__pkLeC:hover{
    background-color: rgba(0, 0, 0, 0.536);
}

.MusicPopup_wrapper__pkLeC img{
    width: 30px;
    padding-left: 10px;
}

@media screen and (max-width: 500px) {
    .MusicPopup_wrapper__pkLeC{
        right: 5%;
    }
}
.Modal_modal__3jQlX{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0, 0.5);
    display: none;
    z-index: 999;
}

.Modal_modalContent__1lM30{
    background-color: #fff;
    padding: 25px;
    max-width: 500px;
}

.Modal_modal__3jQlX.Modal_active__3tzm4{
    display: flex;
    justify-content: center;
    align-items: center;
}
.AgePass_title__25uq_{
    font-size: 30px;
}

.AgePass_btns__2_9u9{
    display: flex;
    justify-content: space-between;
    font-size: 25px;
}

.AgePass_btns__2_9u9 div{
    padding: 5px 10px;
    cursor: pointer;
}

.AgePass_confirm__1DbX7{
    background-color: #E8B82F;
}

.AgePass_decline__J-7S_{
    background-color: #e8512f;
}

.AgePass_wrapper__2Kh4y{
    color: #000;
}


@font-face {
    font-family: green-groove;
    src: url("/static/media/Green Grove.463c9c47.otf");
}

@font-face {
    font-family: green-groove-light;
    src: url("/static/media/Green Grove Light.4003e3b9.otf");
}

@media screen and (max-width: 1600px){
    .section-text{
        font-size: 25px !important;
    }
    .header-links{
        margin-top: 30px !important;
    }
}
*{
    box-sizing: border-box;
}

body{
    font-family: green-groove;
    position: relative;
    background-image: url(/static/media/regbg.42b12a1c.png);
    background-size: cover;
    background-position: 50% 20%;
    color: #fff;
    overflow-x: hidden;
    margin: 0 auto;
}

a{
    color: #fff;
    text-decoration: none;
}

:root{
    --font-yellow: #E8B82F;
}

.text-shadow{
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.593);
}

.page-header{
    color: #E8B82F;
    color: var(--font-yellow);
}

.date{
    font-size: 24px;
    color: #E8B82F;
    color: var(--font-yellow);
    font-weight: bold;
    margin-top: 5px;
}

@media screen and (max-width: 800px){
    .page-header{
        font-size: 20px;
        margin: 30px 0;
    }
}


